import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './JsonList.css';

const JsonList = () => {
  const [jsonFiles, setJsonFiles] = useState([]);
  const navigate = useNavigate();
  const API_PASSWORD = 'SecuredServer$$45';

  useEffect(() => {
    const fetchJsonFiles = async () => {
      try {
        const res = await axios.get('https://json-backend.vercel.app/json-files');
        setJsonFiles(res.data);
      } catch (error) {
        console.error("Error fetching JSON files:", error);
      }
    };
    fetchJsonFiles();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://json-backend.vercel.app/json/${id}`, {
        headers: {
          'x-api-password': API_PASSWORD,
        },
      });
      setJsonFiles(jsonFiles.filter((file) => file._id !== id));
      alert("File deleted successfully");
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Ensure the password is correct.");
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit/${id}`);
  };

  const handleHomeRedirect = () => {
    navigate('/');
  };

  return (
    <div className="json-list">
      <h2>JSON Files List</h2>
      
      {/* Button to redirect to home page */}
      <button onClick={handleHomeRedirect} className="home-button">
        Upload Json
      </button>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Link</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jsonFiles.map((file) => (
            <tr key={file._id}>
              <td data-label="Name">{file.name}</td>
              <td data-label="ID">{file._id}</td>
              <td data-label="Link">
                <a href={`https://json-backend.vercel.app/json/${file._id}`} target="_blank" rel="noopener noreferrer">View JSON</a>
              </td>
              <td data-label="Actions">
                <button onClick={() => handleEdit(file._id)}>Edit</button>
                <button onClick={() => handleDelete(file._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JsonList;
