import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './JsonEditor.css';

const JsonEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState({});
  const [jsonText, setJsonText] = useState(''); // Store JSON as text for JSON view
  const [name, setName] = useState('');
  const [viewMode, setViewMode] = useState('form');
  const API_PASSWORD = 'SecuredServer$$45';

  // Fetch JSON data on component mount
  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        const res = await axios.get(`https://json-backend.vercel.app/json/${id}`, {
          headers: {
            'x-api-password': API_PASSWORD,
          },
        });
        setJsonData(res.data.data);
        setJsonText(JSON.stringify(res.data.data, null, 2)); // Initialize JSON text
        setName(res.data.name);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
        alert("Access denied or error fetching data.");
      }
    };
    fetchJsonData();
  }, [id]);

  const handleNameChange = (e) => setName(e.target.value);

  // Save changes back to the server
  const handleSave = async () => {
    try {
      await axios.put(
        `https://json-backend.vercel.app/json/${id}`,
        { name, data: jsonData },
        {
          headers: {
            'x-api-password': API_PASSWORD,
          },
        }
      );
      alert("JSON updated successfully!");
      navigate("/json");
    } catch (error) {
      console.error("Error saving JSON data:", error);
      alert("Save failed.");
    }
  };

  // Handle JSON text change in the textarea
  const handleJsonChange = (e) => {
    const text = e.target.value;
    setJsonText(text); // Update the text in the textarea

    try {
      const parsedData = JSON.parse(text);
      setJsonData(parsedData); // Update jsonData if JSON is valid
    } catch (error) {
      console.error("Invalid JSON format"); // Log error if JSON is invalid
    }
  };

  // Handle changes to specific fields in form view
  const handleFieldChange = (path, value) => {
    const updateData = (obj, pathArr) => {
      if (pathArr.length === 1) {
        obj[pathArr[0]] = value;
      } else {
        updateData(obj[pathArr[0]], pathArr.slice(1));
      }
    };

    setJsonData((prevData) => {
      const newData = { ...prevData };
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2)); // Update jsonText to reflect changes
      return newData;
    });
  };

  const handleAddField = (path, type) => {
    const updateData = (obj, pathArr) => {
      if (pathArr.length === 0) {
        if (type === 'array') {
          if (Array.isArray(obj)) obj.push({});
          else console.error("Target is not an array");
        } else if (type === 'object') {
          obj[`newField${Object.keys(obj).length + 1}`] = '';
        }
      } else {
        updateData(obj[pathArr[0]], pathArr.slice(1));
      }
    };

    setJsonData((prevData) => {
      const newData = { ...prevData };
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2)); // Update jsonText to reflect changes
      return newData;
    });
  };

  const handleDeleteField = (path) => {
    const updateData = (obj, pathArr) => {
      if (pathArr.length === 1) {
        if (Array.isArray(obj)) obj.splice(pathArr[0], 1);
        else delete obj[pathArr[0]];
      } else {
        updateData(obj[pathArr[0]], pathArr.slice(1));
      }
    };

    setJsonData((prevData) => {
      const newData = { ...prevData };
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2)); // Update jsonText to reflect changes
      return newData;
    });
  };

  const handleMoveField = (path, direction) => {
    const updateData = (obj, pathArr) => {
      const index = pathArr[pathArr.length - 1];
      const parent = pathArr.length === 1 ? obj : pathArr.slice(0, -1).reduce((acc, key) => acc[key], obj);
      if (Array.isArray(parent) && ((direction === 'up' && index > 0) || (direction === 'down' && index < parent.length - 1))) {
        const targetIndex = direction === 'up' ? index - 1 : index + 1;
        [parent[index], parent[targetIndex]] = [parent[targetIndex], parent[index]];
      }
    };

    setJsonData((prevData) => {
      const newData = { ...prevData };
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2)); // Update jsonText to reflect changes
      return newData;
    });
  };

  const handleFieldNameChange = (path, newName) => {
    const updateData = (obj, pathArr) => {
      if (pathArr.length === 1) {
        const value = obj[pathArr[0]];
        delete obj[pathArr[0]];
        obj[newName] = value;
      } else {
        updateData(obj[pathArr[0]], pathArr.slice(1));
      }
    };

    setJsonData((prevData) => {
      const newData = { ...prevData };
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2)); // Update jsonText to reflect changes
      return newData;
    });
  };

  // Render form fields recursively for form view
  const renderFormFields = (data, path = []) => {
    if (typeof data === 'object' && !Array.isArray(data)) {
      return (
        <div className="field-group">
          {Object.keys(data).map((key) => (
            <div key={key} className="field">
              <input
                type="text"
                value={key}
                onChange={(e) => handleFieldNameChange([...path, key], e.target.value)}
                className="input-field-name"
              />
              {renderFormFields(data[key], [...path, key])}
              <button onClick={() => handleDeleteField([...path, key])} className="delete-button">Delete Field</button>
            </div>
          ))}
          <button onClick={() => handleAddField(path, 'object')} className="add-button">Add Field</button>
        </div>
      );
    } else if (Array.isArray(data)) {
      return (
        <div className="array-group">
          {data.map((item, index) => (
            <div key={index} className="array-item">
              <label className="array-label">Array Item {index + 1}</label>
              {renderFormFields(item, [...path, index])}
              <button onClick={() => handleMoveField([...path, index], 'up')} className="move-button">Move Up</button>
              <button onClick={() => handleMoveField([...path, index], 'down')} className="move-button">Move Down</button>
              <button onClick={() => handleDeleteField([...path, index])} className="delete-button">Delete Item</button>
            </div>
          ))}
          <button onClick={() => handleAddField(path, 'array')} className="add-button">Add Array Item</button>
        </div>
      );
    } else {
      return (
        <input
          type="text"
          value={data}
          onChange={(e) => handleFieldChange(path, e.target.value)}
          className="input-field"
        />
      );
    }
  };

  return (
    <div className="json-editor">
      <h2>Edit JSON Data</h2>

      <input
        type="text"
        value={name}
        onChange={handleNameChange}
        placeholder="Enter JSON file name"
        className="name-input"
      />

      <button onClick={() => setViewMode(viewMode === 'form' ? 'json' : 'form')} className="toggle-button">
        Switch to {viewMode === 'form' ? 'JSON View' : 'Form View'}
      </button>

      <div className="view-container">
        {viewMode === 'form' ? (
          <div className="form-fields">
            {renderFormFields(jsonData)}
          </div>
        ) : (
          <textarea
            value={jsonText}
            onChange={handleJsonChange}
            rows="15"
            style={{ width: '100%', fontSize: '14px' }}
          />
        )}
      </div>

      <button onClick={handleSave} className="save-button">Save Changes</button>
    </div>
  );
};

export default JsonEditor;
