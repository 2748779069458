import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UploadForm from './components/UploadForm';
import JsonList from './components/JsonList';
import JsonEditor from './components/JsonEditor';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute element={<UploadForm />} />} />
        <Route path="/json" element={<ProtectedRoute element={<JsonList />} />} />
        <Route path="/edit/:id" element={<ProtectedRoute element={<JsonEditor />} />} />
      </Routes>
    </Router>
  );
}

export default App;
