import React, { useState } from 'react';
import axios from 'axios';
import "./UploadForm.css";
import { useNavigate } from 'react-router-dom';

const UploadForm = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState(null);  // State for JSON data
  const [name, setName] = useState(''); // State for name
  const [dragActive, setDragActive] = useState(false);
  const API_PASSWORD = 'SecuredServer$$45'; // API password for authorization

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    parseJSONFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      setFile(droppedFile);
      parseJSONFile(droppedFile);
    }
  };

  const parseJSONFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const parsedData = JSON.parse(e.target.result);
        setJsonData(JSON.stringify(parsedData, null, 2)); // Convert to formatted JSON string for editing
      } catch (error) {
        alert("Invalid JSON file");
      }
    };
    reader.readAsText(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleJsonChange = (e) => {
    setJsonData(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!jsonData || !name) {
      alert("Please enter a name and select a valid JSON file before uploading.");
      return;
    }

    try {
      const parsedData = JSON.parse(jsonData); // Validate JSON format
      const formData = new FormData();
      formData.append('name', name); // Append the name
      formData.append('file', new Blob([JSON.stringify(parsedData)], { type: 'application/json' }), 'edited.json');

      // Send POST request with password header
      const res = await axios.post('https://json-backend.vercel.app/upload-json', formData, {
        headers: {
          'x-api-password': API_PASSWORD,
        },
      });
      
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Invalid JSON format or upload failed.");
    }
  };

  const handleHomeRedirect = () => {
    navigate('/json');
  };

  return (
    <div className="upload-form">
       <button onClick={handleHomeRedirect} className="home-button">
        Json List
      </button>
      <form onSubmit={handleSubmit}>
        {/* Name input field */}
        <input
          type="text"
          placeholder="Enter file name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="name-input"
        />

        <div 
          className={`drag-drop-area ${dragActive ? 'drag-active' : ''}`}
          onDragOver={handleDragOver} 
          onDragLeave={handleDragLeave} 
          onDrop={handleDrop} 
        >
          {dragActive 
            ? <p>Drop the file here...</p>
            : <p>Drag & Drop JSON file here or click to select</p>
          }
          <input 
            type="file" 
            onChange={handleFileChange} 
            accept=".json" 
            style={{ display: 'none' }}
            id="fileInput"
          />
          <label htmlFor="fileInput">Browse File</label>
        </div>

        {/* JSON Editor */}
        {jsonData && (
          <div className="json-editor">
            <h3>Edit JSON Data</h3>
            <textarea
              value={jsonData}
              onChange={handleJsonChange}
              rows="15"
              style={{ width: '100%', fontSize: '14px' }}
            />
          </div>
        )}

        <button type="submit" className="upload-button">Upload JSON</button>
      </form>
    </div>
  );
};

export default UploadForm;
