// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Replace with your authentication endpoint
      const res = await axios.post('https://json-backend.vercel.app/login', { username, password });

      // Set token and expiration time (12 hours)
      const token = res.data.token;
      const expirationTime = new Date().getTime() + 12 * 60 * 60 * 1000; // 12 hours from now

      localStorage.setItem('token', token);
      localStorage.setItem('expirationTime', expirationTime);

      navigate('/json'); // Redirect to protected page
    } catch (error) {
      console.error("Login failed:", error);
      alert("Invalid credentials");
    }
  };

  return (
    <div className='LoginContainer'>
      <h2 className='LoginTitle'>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
