// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const token = localStorage.getItem('token');
  const expirationTime = localStorage.getItem('expirationTime');

  // Check if token exists and is still valid
  if (!token || new Date().getTime() > expirationTime) {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;
